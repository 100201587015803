<template lang="pug">
  .FormDocumentsValidation
    p(v-if="loading").text-sm.text-info_color.mx-auto.max-w-xs.text-center.mt-5
      | Estamos verificando la información ingresada
    div.input-container.m-3.mx-12(v-if="formDocuments && formDocuments.length")
      .flex.items-center(
        v-for="(formDocument, formDocumentIndex) in formDocuments"
        :key="formDocumentIndex"
        :id="`formDocument_label_${formDocumentIndex}`")
        input-component(
          :value="formDocument.numero"
          :id="`${formDocumentIndex}`"
          name="formDocument"
          type="text"
          mode="input"
          appendMore
          required
          readonly
        )
        button(
          :class="loading ? 'opacity-50 cursor-not-allowed' : ''"
          type="button"
        ).block.m-4.my-0.mt-4.Arrow.ArrowScoped.rounded-bd-complete.flex.items-center.justify-center.border-none.outline-none
          span.material-icons-outlined.text-primary_other image
        img.w-full.maximagLoad.mt-2(v-if="loading" src="https://ntrens.com.ar/imagenes/cargando.gif" alt="loading")
        div(
          v-if="!loading && `${formDocument.resultado}` && `${formDocument.resultado}` !== `${2}`"
        ).block.mx-0.my-0.mt-4.Arrow.bg-danger_background.ArrowScoped.rounded-bd-complete.flex.items-center.justify-center.border-none.outline-none
          span.material-icons-outlined.text-danger_color warning_amber
        div(
          v-if="!loading && `${formDocument.resultado}` && `${formDocument.resultado}` === `${2}`"
        ).block.mx-0.my-0.mt-4.Arrow.bg-good_background.ArrowScoped.rounded-bd-complete.flex.items-center.justify-center.border-none.outline-none
          span.material-icons-outlined.text-good_color check_circle
      div(
        v-if="warningForms.length && !loading"
      ).rounded-bd-small.bg-danger_background.p-3.py-2.flex.mt-8.items-center
        span.material-icons-outlined.text-danger_color.mr-2 warning_amber
        p.leading-4: small.text-danger_color
          | Presiona continuar para resolver el error....
      div(
        v-if="!warningForms.length && !loading"
      ).rounded-bd-small.bg-good_background.p-3.py-2.flex.mt-8.items-center
        span.material-icons-outlined.text-good_color.mr-2 check_circle
        p.leading-4: small.text-good_color
          | Presiona continuar para agendar tu cita
    footer.Footer.fixed.p-5.pt-2.max-w-lg.w-full
      button-component(
        text="Continuar"
        themeSelected="secondary"
        :onClick="handleContinue"
        type="button"
        :disabled="!formDocuments || (formDocuments && !formDocuments.length) || loading"
        v-if="!loading"
      )
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  name: "FormDocumentsValidation",
  data() {
    return {
      // responses: [
      //   {
      //     number: "56678945664513",
      //     resultado: 2,
      //     resultado_descripcion: "fórmula habilitada para dispensación",
      //     puntos: [
      //       2,
      //       3
      //     ],
      //   },
      // ],
      // response: null,
    };
  },
  components: {
    ButtonComponent: () => import("../components/general/Button.vue"),
    InputComponent: () => import("../components/general/Input.vue"),
  },
  created() {
    this.getAllData();
  },
  computed: {
    ...mapState({
      formDocuments: (state) => state.formDocuments.formDocumentsState,
      error: (state) => state.formDocuments.error,
      loading: (state) => state.formDocuments.loading,
      errorFiles: (state) => state.errorFiles,
    }),
    warningForms() {
      return this.formDocuments?.filter((formDocument) => formDocument.resultado !== 2) || [];
    },
  },
  methods: {
    ...mapActions("root", [
      "changeTrip",
    ]),
    ...mapActions("drugstore", [
      "fetchDrugstoresList",
    ]),
    ...mapMutations("root", [
      "setState",
    ]),
    handleContinue() {
      // this.change
      if (this.warningForms.length) {
        this.goToError(this.warningForms[0]);
        return;
      }
      this.fetchDrugstoresList();
      this.changeTrip("Home_appointments");
    },
    goToError(formSelected) {
      this.setState({ key: "formSelected", value: formSelected, });
      this.changeTrip("FormDocumentsValidationInfo");
    },
    getAllData() {
      // const resultApi = Math.floor(Math.random() * 4);
      // this.response = this.responses[resultApi];
    },
  },
}
</script>

<style scoped>

</style>